<template>
<form>

    <v-select
      v-model="select"
      :items="items"
      :error-messages="selectErrors"
      label="Source system"
      required
      @change="$v.select.$touch()"
      @blur="$v.select.$touch()"
    ></v-select>

    <v-text-field
      v-model="systemname"
      :error-messages="nameErrors"
      :counter="10"
      label="Applicant system name"
      required
      @input="$v.systemname.$touch()"
      @blur="$v.systemname.$touch()"
    ></v-text-field>
    <v-text-field
      v-model="purpose"
      :error-messages="purposeErrors"
      label="Purpose for system access"
      required
      @input="$v.purpose.$touch()"
      @blur="$v.purpose.$touch()"
    ></v-text-field>

    <v-text-field
      v-model="owner"
      :error-messages="ownerErrors"
      label="System owner - name + initials"
      required
      @input="$v.owner.$touch()"
      @blur="$v.owner.$touch()"
    ></v-text-field>

    <v-text-field
      v-model="costcenter"
      :error-messages="nameErrors"
      :counter="4"
      label="Cost center (note a cost of 2500 DKK/yr)"
      required
      @input="$v.costcenter.$touch()"
      @blur="$v.costcenter.$touch()"
    ></v-text-field>
    
    <v-checkbox
      v-model="checkbox"
      :error-messages="checkboxErrors"
      label="Do you agree to the terms of use stated above?"
      required
      @change="$v.checkbox.$touch()"
      @blur="$v.checkbox.$touch()"
    ></v-checkbox>

    <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="deep-purple accent-4"
      ></v-progress-linear>
    
    <template>
    <div
      v-if="!loading"
    >
    <v-btn
      dark
      color="orange darken-2"
      @click="submit()"
    >
      Submit
    </v-btn>
    <v-btn @click="clear">
      clear
    </v-btn>
    </div>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
    </template>


    
  </form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import axios from 'axios';

export default {
    name: 'accessform',
    props: ['username'],
    mixins: [validationMixin],

    validations: {
      systemname: { required, maxLength: maxLength(10) },
      purpose: { required },
      select: { required },
      owner: { required },
      checkbox: {
        checked (val) {
          return val
        },
      },
    },

    data: () => ({
      systemname: '',
      costcenter: '',
      purpose: '',
      select: null,
      owner: '',
      items: [
        'IMPACT',
        'ePID',
        'IWRS',
        'EDC',
      ],
      checkbox: false,
      snackbar: false,
      text: 'Thanks for Submitting.',
      timeout: 2000,
      loading: false,
    }),

    computed: {
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('You must agree to continue!')
        return errors
      },
      selectErrors () {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('Item is required')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.systemname.$dirty) return errors
        !this.$v.systemname.maxLength && errors.push('Name must be at most 10 characters long')
        !this.$v.systemname.required && errors.push('Name is required.')
        return errors
      },
      purposeErrors () {
        const errors = []
        if (!this.$v.purpose.$dirty) return errors
        !this.$v.purpose.required && errors.push('Name is required.')
        return errors
      },
       ownerErrors () {
        const errors = []
        if (!this.$v.owner.$dirty) return errors
        !this.$v.owner.required && errors.push('Name is required.')
        return errors
      }
    },

    methods: {
    async  submit () {
        console.log(this.appConfig.api_base_url);
        this.$v.$touch();
        if (this.$v.$invalid) return;
        this.loading=true;
        this.snackbar = true; 
        axios.post(
            `${this.appConfig.api_base_url}/create_agreement`, 
            {
                systemname: this.systemname,
                purpose: this.purpose,
                owner: this.owner,
                source: this.select,
                agree: this.checkbox,
                user: this.username,
                loading: false,
            }
        ).then((response) => {
            console.log(response);
            this.$emit("inputChange",false);
        }, (error) => {
            console.log(error);
        });
        
    },
    clear () {
        this.$v.$reset()
        this.systemname = ''
        this.purpose = ''
        this.owner = ''
        this.select = null
        this.checkbox = false
      },
    },
  }
</script>
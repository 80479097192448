<template>
<v-container>
    <h1 class="display-1 font-weight-bold mb-3">
        How to access
    </h1>
    <v-row
    class="mb-12"
    justify="start"
    >
    <v-col cols="12">
    <v-card
    grey
    >
  <v-expansion-panels>
    <v-expansion-panel
      v-for="(item,i) in concepts"
      :key="i"
    >
      <v-expansion-panel-header>
        <h4>{{ item.title }} </h4>
      </v-expansion-panel-header>
      
      <v-expansion-panel-content>
        
        <v-row
        class="mb-12"
        justify="start"
        >

        <v-col cols="12">
            <p class="text-left" v-html="item.text"></p>
        </v-col>
         <v-divider inset></v-divider>
        <v-col cols="12">
            <h3 class="text-left"> Connection details </h3>
            <p class="text-left"> Hostname: {{host}} </p>
            <p class="text-left"> Database: {{database}} </p>
            <p class="text-left"> Port: {{port}} </p>
        </v-col>

        <v-col 
        md="6"
        offset-md="3">
        <v-img :src="item.img"></v-img>
        </v-col>
        
        

        </v-row>
        
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
   </v-card>
   </v-col>
</v-row>
</v-container>
</template>


<script>


export default {
  name: 'access',
  data: () => ({
    concepts: [
      {
        title: 'Access using SQL Server Management studio',
        img: require('../assets/ssms.png'),
        text: 'You can access the CDR server using SSMS by selecting <code> Active Directory using MFA </code> ',
        color: '#1F7087'
      },
      {
        title: 'Access using python',
        img: require('../assets/pythonr.png'),
        text: `You can access the CDR server using python by installing pyodbc 
        <div v-highlight >
        <pre class="language-python">
        server = 'cdr-database-dev.database.windows.net'
        database = 'cdr-fast'
        username ='{initials}@novonordisk.com'
        Authentication='ActiveDirectoryInteractive'
        driver= '{ODBC Driver 17 for SQL Server}' 
        </pre>
        </div>
        `,
        color: '#1F7087'
      },
      {
        title: 'Access using R',
        img: require('../assets/pythonr.png'),
        text: `You can access the CDR server using R by installing DBI and odbc 
        <div v-highlight >
        <pre class="language-python">
        server <- "cdr-database-dev.database.windows.net"
        database <- "cdr-fast"
        username <- "{initials}@novonordisk.com"
        con <- DBI::dbConnect(
          odbc::odbc(), 
          UID = username,
          Driver="ODBC Driver 17 for SQL Server",
          Server = server, Database = database,
          Authentication = "ActiveDirectoryInteractive"
        )
        </pre>
        </div>
        `,
        color: '#1F7087'
      },
      {
        title: 'Access using Azure Data studio studio',
        img: require('../assets/azstudio.png'),
        text: 'You can access the CDR server using Azure Data Studio by selecting <code> Active Directory using MFA </code> ',
        color: '#1F7087'
      },
    ],
    host: 'cdr.database.net',
    database: 'cdr',
    port: 1433,

  }),
};
</script>
<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        
        <h1 class="display-1 font-weight-bold mb-3">
          System access
        </h1>
        <v-col cols="12">
        <p> If you need to integrate with the data using a service account to proxy access to your users, please fill out the following form </p>
        </v-col>

        <v-col cols="12">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          System Access
        </v-btn>
        
        </v-col>

      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>CDR System access</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <v-list
          three-line
          subheader
        >
          <v-subheader>Terms of use</v-subheader>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Important</v-list-item-title>
              <v-list-item-subtitle>
              
              It is the System owner / applicants responsibility to ensure compliance with Novo Nordisk and external requirements (e.g. third-party data license limitations, NN Tier1, Sarbox, GDPR) and to obtain internal review or approvals as necessary.
              
              </v-list-item-subtitle>
             <v-list-item-subtitle>
             Specifically, it is the responsibility of the applicant to ensure that any and all data that are being extracted from this interface are handled in a manner that meets local Data Privacy and Protection requirements 
             (i.e., GDPR* for EU data, or any other country or region requirement for data management and use).
             Procedures required for obtaining data licenses or permissions to store and use data will be handled exclusively by the applicant and the Line of Business. 
             
             For personal data, that may include opt-in agreements from customers or employees. 
             
             Local Novo Nordisk Legal representation should be consulted by the applicant to ensure alignment with all local laws
            
             These requirements will be implemented as specified. It is the responsibility of the applicant to ensure that the appropriate level of 
             data protection is requested prior to extracting data from the interface.

            Applicant should consult the data protection officer if planning to extract the data to a physical location outside of EU.

            The applicant need to ensure proper access management to any application serving data from this interface.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Password</v-list-item-title>
              <v-list-item-subtitle>Password is set to expire after 12 months as a default setting</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        
        <v-list
          three-line
          subheader
        >
          <v-subheader>General</v-subheader>
            <v-col 
        md="10"
        offset-md="1">
        <accessform :username="username" v-on:inputChange="handleChange"/>
        </v-col>
          

        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import accessform from './form';

export default {    
  name: 'modal',
  components: {
      accessform,
  },
  props: [
  'username'
  ],

  data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
      }
    },
    methods: {
    async  handleChange () {
        this.dialog = false;
      }
    },
};
</script>


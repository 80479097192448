<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="./assets/logo.png"
          transition="scale-transition"
          width="60"
        />


      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://dev.azure.com/novonordiskit/Project%20Stratus"
        target="_blank"
        text
      >
        <span class="mr-2">Our ADO {{user.name}}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-layout
        text-center
        wrap
      >
     

      <datasets/>
      <para/>
      <access/>
      <modal :username="user.name"/>
      <para/>
      <team/>
      <foot/>
      </v-layout>
    </v-main>
  </v-app>
</template>


<script>
import datasets from './components/datasets';
import team from './components/team';
import access from './components/access';
import modal from './components/modal';
import para from './components/paralax';
import foot from './components/footer';
import { msalMixin } from 'vue-msal'; 

export default {
  name: 'App',

  components: {
    datasets,
    team,
    access,
    modal,
    para,
    foot
  },
  mixins: [msalMixin],
    computed: {
        user() {
          let user = null;
          if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
            user = {
              ...this.msal.user,
              profile: {}
            }
            if (this.msal.graph && this.msal.graph.profile) {
                user.profile = this.msal.graph.profile
            }
          }
          return user;
        }
    },

  data: () => ({
    //
  }),
};
</script>

<template>
<v-container>
    <v-row
    class="mb-12"
    justify="start"
>
<v-col cols="12">
  <v-parallax
    height="300"
    src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
  ></v-parallax>
  </v-col>
</v-row>
</v-container>
</template>

<script>
export default {
  name: 'para',
};
</script>

<template>
  <v-container>
    

      <v-flex mb-4>
        <h1 class="display-1 font-weight-bold mb-3">
          Available datasets
        </h1>
          <!-- App section  -->

  <v-row dense>
    <v-col
      md="3"
      lg="3"
      v-for="(app, i) in apps"
            :key="i"
    >
      <v-card>
       <v-list-item>
         <v-list-item-avatar :color="app.color"></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline"> {{ app.name }} </v-list-item-title>
            <v-list-item-subtitle> {{ app.access_level }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      <v-img
        :src='app.img'
      ></v-img>
      <!-- -->
      <v-card-text
      justify="start"
      >
          <p class="text-left font-weight-bold">NovoAccess: {{ app.access }}</p>

      </v-card-text>

      <v-card-actions>

        <v-btn
          text
          color="deep-purple accent-4"
          :href='app.href' 
          target="_blank"
        >
          Sharepoint
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
        icon
        @click="app.show = !app.show"
        >
          <v-icon>{{ app.show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
        
      </v-card-actions>

      <v-expand-transition>
        <div v-show="app.show">
          <v-divider></v-divider>

          <v-card-text>
            {{ app.text }}
          </v-card-text>

           
          
        </div>
      </v-expand-transition>

      </v-card>
    </v-col>
  </v-row>
  </v-flex>
    
  </v-container>
</template>

<script>
export default {
  name: 'datasets',

  data: () => ({
    apps: [
      {
        author: 'IMPACT',
        img:  require('../assets/code.png'),
        name: 'IMPACT',
        access: 'CTMS reader',
        access_level: 'not unblinding',
        all_trials: 'yes',
        href: 'https://novonordisk.sharepoint.com/sites/IMPACT',
        text: 'IMPACT is a computer system which supports clinical trial activities from phase 1 (0) to phase 4. The system can be used for administration of complex multinational trials just as effectively as simple, single centre, single country trials. All trials sponsored by Novo Nordisk must be recorded and updated in IMPACT. Investigator sponsored trials may be recorded in the system. IMPACT provides a database of planned, current and completed trials and is constantly updated by Monitors and Trial Managers. In addition to trial progress tracking and subject recruitment information, IMPACT also holds valuable reference information: All clinical centres and all clinical personnel participating in Novo Nordisk trials are recorded in the system with names, addresses, telephone numbers etc.',
        show: false,
        color: 'grey'
      },
      {
        author: 'IWRS',
        img: require('../assets/code.png'),
        name: 'IWRS',
        access_level: 'potentially unblinding',
        access: 'RTSM reader',
        all_trials: 'No',
        href: 'https://novonordisk.sharepoint.com/sites/TrialProductHandling/SitePages/IWRS.aspx',
        text: ' IWRS - the Interactive Web Response System - The IWRS is a web based IT system used to support the Randomisation and Trial Supply Management in clinical trials. The IWRS is provided by the supplier Parexel Informatics, who offers 24/7 technical support via their Customer Portal.',
        show: false,
        color: 'blue lighten-3'
      },
      {
        author: 'ePID',
        img: require('../assets/code.png'),
        name: 'ePID',
        access_level: 'potentially unblinding',
        access: 'ePID : {trial} Data Manager',
        all_trials: 'No',
        href: 'https://novonordisk.sharepoint.com/sites/EletronicPatientInteractionDevice-ePID/SitePages/What-is-ePID-.aspx',
        text: 'What is ePID? ePID is the Electronic Patient Interaction Device which increases transparency of data for patients, health care professionals (HCPs) and for Novo Nordisk trial team. It is a platform combining a web interface dashboard for HCPs and Novo Nordisk colleagues, an app interface for patients and connected devices of different kinds, e.g. Blood Glucose Meters (BGMs), activity trackers and/or scales.',
        show: false,
        color: 'teal lighten-5'
      },
      {
        author: 'EDC',
        img: require('../assets/code.png'),
        name: 'EDC',
        access_level: 'potentially unblinding',
        access: 'EDC reader',
        all_trials: 'No',
        href: 'https://novonordisk.sharepoint.com/sites/EDC',
        text: 'The NN Electronic Data Capture system (EDC) is our computerized system designed for the collection of clinical data in electronic format for use in clinical trials. EDC replaces the traditional paper-based data collection methodology to streamline data collection and expedite the time to market for our medicines and medical devices. Primarily the EDC systems provides a graphical user interface component for data entry, a validation component to check user data and a reporting tool for analysis of the collected data.',
        show: false,
        color: 'pink lighten-4'
      },
      {
        author: 'DMW',
        img: require('../assets/code.png'),
        name: 'DMW',
        access_level: 'potentially unblinding',
        access: 'DMW reader',
        requirement: 'Request Access through NovoAccess. Please note basic CDMS training must have been completed prior to requesting access to the CDMS Production Environment.',
        all_trials: 'No',
        href: 'https://di.corp.novocorp.net/__login__?url=https%3A%2F%2Fdi.corp.novocorp.net%2Fcliqit%2F',
        text: 'The best tool for finding trends in Protocol Deviations.',
        show: false,
        color: 'grey'
      },
      {
        author: 'EDC',
        img: require('../assets/code.png'),
        name: 'IMPACT raw',
        access_level: 'potentially unblinding',
        access: 'CTMS specialist',
        all_trials: 'No',
        href: 'https://di.corp.novocorp.net/__login__?url=https%3A%2F%2Fdi.corp.novocorp.net%2Fcliqit%2F',
        text: 'The best tool for finding trends in Protocol Deviations.',
        show: false,
        color: 'grey'
      },
    ]
  }),
};
</script>

import { render, staticRenderFns } from "./datasets.vue?vue&type=template&id=3faca01f&"
import script from "./datasets.vue?vue&type=script&lang=js&"
export * from "./datasets.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCol,VContainer,VDivider,VExpandTransition,VFlex,VIcon,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VSpacer})

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueCodeHighlight from 'vue-code-highlight';
import msal from 'vue-msal'
import { config } from './config';
 
require('@/assets/window.css')
require('@/assets/duotone-sea.css')


Vue.prototype.appConfig = config;

Vue.use(VueCodeHighlight)
Vue.config.productionTip = false
Vue.use(msal, {
  auth: {
    clientId: '8679ae51-9fda-433d-8a17-44a358c32364',
    tenantId: 'fdfed7bd-9f6a-44a1-b694-6e39c468c150',
    redirectUri: config.redirect_url
  }
});

new Vue({
  vuetify,
  created() {
    if (!this.$msal.isAuthenticated()) {
        this.$msal.signIn();
    }
  },
  render: h => h(App)
}).$mount('#app')

let config;

let baseUrl = window.location.hostname;
let origin = window.location.origin;
let api_base_url = `https://api.${baseUrl}` 

console.log(process.env.VUE_APP_BASE_URL);
console.log(process.env.VUE_APP_BASE_API_URL);
console.log(baseUrl);
console.log(window.location);
// host: "site.dev.gdstratus.cloud"
// hostname: "site.dev.gdstratus.cloud"
// href: "https://site.dev.gdstratus.cloud/"
// origin: "https://site.dev.gdstratus.cloud"
if (process.env.VUE_APP_BASE_URL) {
    baseUrl = process.env.VUE_APP_BASE_URL;
    api_base_url = process.env.VUE_APP_BASE_API_URL;
}

console.log(baseUrl);

config = {
    baseUrl: baseUrl,//'https://site.dev.gdstratus.cloud/',
    bootstrapEndpoint: `https://api.${baseUrl}/bootstrap`,
    api_base_url: api_base_url,
    redirect_url: origin,
};

export { config }
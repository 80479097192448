<template>
<v-container>
    <v-row
    class="mb-12"
    justify="start"
    >
    <v-col
    class="text-center"
    cols="12"
    >

  <v-footer>
    <v-col
      class="text-center"
      cols="12"
    >
      {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
    </v-col>
  </v-footer>
  </v-col>  
  </v-row>
</v-container>
</template>

<script>
export default {
  name: 'foot',
};
</script>
<template>
  <v-container>
    <v-row dense></v-row>

      <v-flex mb-4>
      
      <h1 class="display-1 font-weight-bold mb-3">
          Meet our team
      </h1>
      <img
        src='../assets/code.png'
        height="90px"
        width="90px"
      >
      </v-flex>

    <!-- Team members  GD_ePID_NN1436-4625_DataMgr -->
   
    <v-container>
      <v-row 
      
      dense>

        <v-col
          v-for="(member, i) in team"
          :key="i"
          md="6"
          lg="3"
        >
          <v-card
            :color="member.color"
            dark
          >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="headline"
                  v-text="member.init"
                ></v-card-title>

                <v-card-subtitle v-text="member.name"></v-card-subtitle>
                <v-card-text class="text-truncate">
                  {{ member.title }}

                </v-card-text>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-img :src="member.img"></v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>


<script>
export default {
  name: 'team',

  data: () => ({
    team: [
      {
        init: 'HLYL',
        name: 'Henrik',
        title: 'VP',
        img: require('../assets/code.png'),
        color: '#1F7087'
      },
      {
        init: 'DOCA',
        name: 'Doruk',
        title: 'PM',
        img: require('../assets/code.png'),
      },
      {
        init: 'CJLQ',
        name: 'Jorge',
        title: 'Senior Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'NSYL',
        name: 'Sebastian',
        title: 'Lead Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'ZQRL',
        name: 'Søren',
        title: 'Lead DevOps',
        img: require('../assets/code.png'),
        color: '#1F7087'
      },
      {
        init: 'YCBX',
        name: 'Chirstian',
        title: 'Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'EJOQ',
        name: 'Jakob',
        title: 'Lead Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'MWQR',
        name: 'Mihai',
        title: 'SME Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'ABZO',
        name: 'Alexandar',
        title: 'Lead Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'MVLN',
        name: 'Melvin',
        title: 'Senior Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'HHJZ',
        name: 'Hasse',
        title: 'Senior Dev',
        img: require('../assets/code.png'),
      },
      {
        init: 'HHJN',
        name: 'Hans-Henrik',
        title: 'QA',
        img: require('../assets/code.png'),
      },
      {
        init: 'THBO',
        name: 'Thomas',
        title: 'Lead QA',
        img: require('../assets/code.png'),
      },
      {
        init: 'KNXV',
        name: 'Kingston',
        title: 'Dev',
        img: require('../assets/code.png'),
      },
    ],
  }),
};
</script>
